const requiredValidation = (text, name) => (!text || text.trim() == "") ? `${name} is required.` : null;
const emailValidation = (email) => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) ? `Invalid email format.` : null;

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.toCustomDateString = function () {
    let date = new Date(this.valueOf());
    
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    var day = days[date.getDay()];
    var month = months[date.getMonth()];
    var dayOfMonth = date.getDate();
    var year = date.getFullYear();

    return `${day}, ${dayOfMonth} ${month} ${year}`;
}

const validateLeadTime = (date) => {    
    return (date < new Date().addDays(2).setHours(0, 0, 0, 0)) ? `At least 2 days lead time expected.` : null;
};

const validateSunday = (date) => {
    const isSunday = date.getDay() === 0;
    return isSunday ? 'Closed on Sundays.' : null;
}

const validateNextAvailabilityDate = (date) => {
    const isFullyBooked = date <= FULLY_BOOKED_DATE;
    return isFullyBooked ? `Sorry, we are booked until ${FULLY_BOOKED_DATE.toCustomDateString()}.` : null;
}

const validateBlockedDays = (date) => {

    let isBlockedDay = false;
    let validationMessage = null;
    for (let index = 0; index < BLOCKED_DAYS.length; index++) {
        const day = BLOCKED_DAYS[index];
        day.setHours(0, 0, 0, 0);

        if (day.getTime() === date.getTime()) {
            isBlockedDay = true;
            validationMessage = `Sorry, we are fully booked on this date.`;
             //Put this back on line 48: 'Sorry , we are closed on ${day.toCustomDateString()}.`;

            break;
        }
    }

    return isBlockedDay ? validationMessage : null;
}

const dateValidation = (date) => {
    const dateOnly = new Date(date);
    dateOnly.setHours(0, 0, 0, 0);

    //Validate Next Availability
    const validateNextAvailabilityDateError = validateNextAvailabilityDate(dateOnly);
    if (validateNextAvailabilityDateError) return validateNextAvailabilityDateError;

    //Validate Blocked Days
    const validateBlockedDaysError = validateBlockedDays(dateOnly);
    if (validateBlockedDaysError) return validateBlockedDaysError;

    //Validate Sunday
    const validateSundayError = validateSunday(dateOnly);
    if (validateSundayError) return validateSundayError;

    //Validate Lead Time
    const validateLeadTimeError = validateLeadTime(dateOnly);
    if (validateLeadTimeError) return validateLeadTimeError;

    //All date validations passed. 
    return null;
};

//Format: YYYY-MM-DD
const FULLY_BOOKED_DATE = new Date("2024-07-13");

const BLOCKED_DAYS = [
    new Date("2024-02-10"),
    new Date("2024-06-01"),
    new Date("2024-06-14"),
    new Date("2023-12-22"),
    new Date("2023-12-23"),
    new Date("2023-12-24"),
    new Date("2023-12-25"),
    new Date("2023-12-26"),
    new Date("2023-12-27"),
    new Date("2023-12-28"),
    new Date("2023-12-29"),
    new Date("2023-12-30"),
    new Date("2023-12-31"),
    new Date("2024-01-01"),
    new Date("2024-01-02"),
    new Date("2024-01-03"),
    new Date("2024-01-04"),
    new Date("2024-01-05"),
    new Date("2024-01-06"),
    new Date("2024-01-07"),
    new Date("2024-01-08"),
    new Date("2024-01-09"),
    new Date("2024-01-10"),
    new Date("2024-10-22"),
    new Date("2024-09-04"),
    //more entries here...
];

const latestDate =  ((FULLY_BOOKED_DATE.addDays(1) > (new Date()).addDays(2)) ?
        FULLY_BOOKED_DATE.addDays(1) :
        (new Date()).addDays(2)).toISOString().split('T')[0];

const maxDate = (new Date()).addDays(365).toISOString().split('T')[0];

export { requiredValidation, emailValidation, dateValidation, latestDate, maxDate }