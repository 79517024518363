import { useStaticQuery, graphql } from "gatsby"

export const useColourPalettes = () => {
  const { allColourPalettesJson } = useStaticQuery(
    graphql`
      query ColourPalettes {
        allColourPalettesJson {
            nodes {
                id
                hex
                colour
            }		
          }
      }
    `
  )
  return allColourPalettesJson.nodes
}